/**
 * Created by stapletont on 28/05/2015.
 */

/** @namespace window.siteObj - Set in JavaScript Head */
/** @namespace siteObj.siteCode */
/** @namespace siteObj.customerLocale */
/** @namespace siteObj.currencyType */
/** @namespace siteObj.ajaxFacetsAPI */

define(['app', 'siteObj', 'urlReader'], function(app, siteObj, urlReader) {

  var __apiUrl = siteObj.ajaxFacetsAPI;

  /**
   * Ajax Facets.
   * @namespace
   */
  var _ajaxFacets = {

    /**
     * Module Constructor
     * @private
     */
    _init: function() {
      _ajaxFacets._bindEvents();
      app.publish('url/updateIdentifiers', '', '', '', '|');

      urlReader.constructor = {
        variableQuery: '?',
        categoryValueSeparator: '=',
        categorySeparator: '&',
        segmentSeparator: '|'
      };

      _ajaxFacets._requestChange = false;

      app.subscribe('url/change', function(type, change) {
        // When the responsive list page is loaded we do not need this function to trigger the
        // facets as they are being handled differently.
        if(document.querySelector('.responsiveProductListPage')) {
          return;
        }

        if (_ajaxFacets._requestChange || type === 'pop') {
          _ajaxFacets._requestFacets(change);
          return;
        }
        _ajaxFacets._requestChange = true;
      });

      app.subscribe('facets/updatePage', _ajaxFacets._updatePageCallback);

      app.subscribe('facets/removeCurrentPage', function(updatedUrl) {
        _ajaxFacets._dynamicPathUpdate = updatedUrl;
      });

      app.publish('url/init');
    },

    _updatePageCallback: function(action, segment, category, clearPageNumber) {
      if (clearPageNumber) {
        _ajaxFacets._clearPageNumber = true;
      }
      _ajaxFacets._requestChange = false;
      _ajaxFacets._updatePage(action, segment, category, '');
    },

    _clearPageNumber: false,

    _requestChange: false,

    _dynamicPathUpdate: '',

    _searchKey: 'search',

    _apiKey: 'facetFilters',

    _pageNumberKey: 'pageNumber',

    _sortOrderKey: 'sortOrder',

    _cachedFacet: {},

    _cachedPath: window.location.href,

    _productListStringTemplate: document.querySelector('[data-productlist]')
      ? document.querySelector('[data-productlist]').getAttribute('data-productlist-st') : null,

    /**
     * Add event to feature facet container
     * @private
     */
    _bindEvents: function() {
      var facetsWrapper = app.select('.js-facets-wrapper');
      facetsWrapper.on('click', _ajaxFacets._facetWrapperClick);

      var modal = app.select('.js-facets-loading-overlay');
      modal.on('click', _ajaxFacets._modalClick);
    },

    /**
     * Get facets from dynamic NON user, URL modification
     * @param location
     * @private
     */
    _requestFacets: function(location) {
      var params = '';
      if (location.indexOf(urlReader.decoupler.variableQuery) > -1) {
        params = location.split(urlReader.decoupler.variableQuery)[1];
        if (params.indexOf('pageNumber') > -1) {
          _ajaxFacets._updatePaginationCache(params);
        } else {
          app.publish('pageControls/updateCache', '1');
        }
      } else {
        app.publish('pageControls/updateCache', '1');
      }

      _ajaxFacets._getData(__apiUrl + urlReader.constructor.variableQuery + params);
    },

    _updatePaginationCache: function(params) {
      var categories = [];
      if (params.indexOf(urlReader.decoupler.categorySeparator)) {
        categories = params.split(urlReader.decoupler.categorySeparator);
      } else {
        categories = params;
      }

      for (var i = 0; i < categories.length; i++) {
        var category = categories[i];
        if (category.indexOf('pageNumber=') > -1) {
          app.publish('pageControls/updateCache', category.split('pageNumber=')[1]);
        }
      }
    },

    // TODO: When all sites are using the new facet component, standardise the class that is targeted for the checkbox
    // Currently the new facets.st uses _ajaxFacets._facetSelectors.checkbox
    // The previous facets.st uses the class 'js-facet' and decides on behaviour according to target.nodeName

    // TODO: when all sites are using the new facets Vulcan component, remove the references to
    // the '_old' classes below

    _facetSelectors: {
      checkbox: 'js-facet-checkbox',
      clearSelected: 'js-facet-clear-filter',
      clearSet: 'js-facet-clear',
      collapse: 'js-facets-collapse',
      openedListPanel: 'facets_collapse-open',
      closedListPanel: 'facets_collapse-closed',
      facetList: '[data-list]',
      closedListPanelList: 'facets_listPanelList-closed',
      noProducts_old: 'zero-products',
      noProducts_new: 'facets_noProducts',
      showLoadingOverlay_old: 'show',
      showLoadingOverlay_new: 'facets_loadingOverlay-show',
    },

    /**
     * Retrieve click event and set target
     * @param event
     * @private
     */
    _facetWrapperClick: function(event) {
      event = event || window.event;
      var target = event.target || event.srcElement;

      if (!target) {
        return;
      }
      _ajaxFacets._requestChange = false;

      // TODO: this will not be needed when all sites are using the new facets.st
      // The target reassignment is needed for the older facets.st that is served from the site-specific
      // directories. The ! class check is required so that the target is not reassigned on the new common
      // Vulcan facets.st.
      if (target.nodeName === 'I' && !app.element.hasClass(_ajaxFacets._facetSelectors.collapse, target)) {
        target = target.parentNode;
      }
      _ajaxFacets._filterClickableElements(target);
    },

    /**
     * Determine click behaviour for facet panel
     * @param target
     * @private
     */
    _filterClickableElements: function(target) {
      // TODO: we won't need the first part of this next 'if' statement when all sites are using the new facets.st
      // In the new common Vulcan facets.st we are NOT using the class js-facet
      if (app.element.hasClass('js-facet', target) || app.element.hasClass(_ajaxFacets._facetSelectors.checkbox, target)) {
        if (target.nodeName === 'INPUT') {
          _ajaxFacets._facetClick(target, target.value);
          return;
        }

        // TODO: we won't need this when all sites are using the new facets.st
        // The new Vulcan facets.st component is non element-specific - this is required for the site-specific
        // older facets.st to continue working after the target has been reassigned in _ajaxFacets._facetWrapperClick.
        if (target.nodeName === 'A') {
          _ajaxFacets._facetClick(target, app.element.getAttribute('data-facet-value', target));
          (event.preventDefault) ? event.preventDefault() : event.returnValue = false;
        }
      }

      if (app.element.hasClass(_ajaxFacets._facetSelectors.clearSelected, target)) {
        _ajaxFacets._facetClick(target, app.element.getAttribute('data-facet-value', target));
        (event.preventDefault) ? event.preventDefault() : event.returnValue = false;
      }

      // TODO: we won't need this when all sites are using the new facets.st
      // This is currently required for the site-specific older facets.st to continue
      // working after the target has been reassigned in _ajaxFacets._facetWrapperClick.
      if (target.nodeName === 'A' && app.element.hasClass(_ajaxFacets._facetSelectors.clearSet, target)) {
        _ajaxFacets._clearFacets(target);
        (event.preventDefault) ? event.preventDefault() : event.returnValue = false;
      }

      if (app.element.hasClass(_ajaxFacets._facetSelectors.clearSet, target)) {
        _ajaxFacets._clearFacets(target);
        (event.preventDefault) ? event.preventDefault() : event.returnValue = false;
      }

      if (target.nodeName === 'I' && app.element.hasClass(_ajaxFacets._facetSelectors.collapse, target)) {
        var facetListName = app.element.getAttribute('data-open-list', target),
          dataListSelector = '[data-list=' + facetListName + ']';

        var facetListToOpen = document.querySelector(dataListSelector);
        _ajaxFacets._facetListCollapse(target.parentNode, facetListToOpen);
        (event.preventDefault) ? event.preventDefault() : event.returnValue = false;
      }
    },

    /**
     * Facet click behaviour
     * @param target
     * @param segment
     * @private
     */
    _facetClick: function(target, segment) {
      _ajaxFacets._cachedFacet = target;
      _ajaxFacets._cachedPath = window.location.href;
      var action = 'remove';

      if (target.checked) {
        action = 'add';
      }

      app.publish('pageControls/removeCurrentPage');
      var params = [action, segment, _ajaxFacets._apiKey];

      if (_ajaxFacets._dynamicPathUpdate) {
        params.push(_ajaxFacets._dynamicPathUpdate);
      }

      _ajaxFacets._updatePage.apply(this, params);
      _ajaxFacets._tracking(target);
      _ajaxFacets._dynamicPathUpdate = '';
    },

    /**
     * Open/close a facet list
     * @param element - parentNode of click target
     * @param facetListPanel - list panel to open/close
     */
    _facetListCollapse: function(element, facetListPanel) {
      if (app.element.hasClass(_ajaxFacets._facetSelectors.openedListPanel, element)) {
        app.element.removeClass(_ajaxFacets._facetSelectors.openedListPanel, element);
        app.element.addClass(_ajaxFacets._facetSelectors.closedListPanel, element);

        app.element.addClass(_ajaxFacets._facetSelectors.closedListPanelList, facetListPanel);
      } else {
        app.element.removeClass(_ajaxFacets._facetSelectors.closedListPanel, element);
        app.element.addClass(_ajaxFacets._facetSelectors.openedListPanel, element);

        app.element.removeClass(_ajaxFacets._facetSelectors.closedListPanelList, facetListPanel);
      }
    },

    /**
     * Clear facet behaviour
     * @param target
     * @private
     */
    _clearFacets: function(target) {
      var sectionToClear = app.element.getAttribute('data-facet-target', target);
      var location = {
        api: {
          url: window.location.href
        }
      };

      // TODO: Remove '.js-facet' when all sites are using the new facets.st
      var facets = document.querySelector(sectionToClear);
      if (facets) {
        facets = facets.querySelectorAll('.js-facet, .' + _ajaxFacets._facetSelectors.checkbox);
      }
      app.publish('pageControls/removeCurrentPage');

      if (_ajaxFacets._dynamicPathUpdate) {
        location.api.url = _ajaxFacets._dynamicPathUpdate;
      }

      for (var i = 0, facetsLength = facets.length; i < facetsLength; i++) {
        var facet = facets[i];
        if (facet.checked) {
          location = urlReader.deconstructGet('remove', facet.value, _ajaxFacets._apiKey, location.api.url);
        }
      }

      var windowUrl = location.api.url;
      var queryParam = '?';
      if (windowUrl.indexOf(_ajaxFacets._apiKey) > -1 || windowUrl.indexOf(_ajaxFacets._sortOrderKey) > -1 || windowUrl.indexOf(_ajaxFacets._pageNumberKey) > -1 || windowUrl.indexOf(_ajaxFacets._searchKey) > -1) {
        queryParam = '&';
      }
      var stringTemplateRequest = _ajaxFacets._productListStringTemplate ? queryParam + 'productListStringTemplate=' + _ajaxFacets._productListStringTemplate : '';

      _ajaxFacets._getData(__apiUrl + location.api.urlVariables + stringTemplateRequest);
      app.publish('url/updatePath', location.window.url);
      _ajaxFacets._dynamicPathUpdate = '';
    },

    /**
     * Request new facets and request url change
     * @param action
     * @param segment
     * @param category
     * @param [dynamicUpdatedPath]
     * @private
     */
    _updatePage: function(action, segment, category, dynamicUpdatedPath) {
      var location = urlReader.deconstructGet(action, segment, category, dynamicUpdatedPath);
      var windowUrl = location.window.url;
      var queryParam = '?';

      if (windowUrl.indexOf(_ajaxFacets._apiKey) > -1 || windowUrl.indexOf(_ajaxFacets._sortOrderKey) > -1 || windowUrl.indexOf(_ajaxFacets._pageNumberKey) > -1 || windowUrl.indexOf(_ajaxFacets._searchKey) > -1) {
        queryParam = '&';
      }

      var stringTemplateRequest = _ajaxFacets._productListStringTemplate ? queryParam + 'productListStringTemplate=' + _ajaxFacets._productListStringTemplate : '';
      var getDataUrl = __apiUrl + location.api.urlVariables + stringTemplateRequest;

      if (_ajaxFacets._clearPageNumber) {
        getDataUrl = _ajaxFacets._removePageNumberFromUrl(getDataUrl);
        windowUrl = _ajaxFacets._removePageNumberFromUrl(windowUrl);
      }

      // remove the trailing ? or & if it exists from the url and selected facets are empty
      // this can happen if all facets have been cleared
      if (!document.querySelector(_ajaxFacets._facetSelectors.checkbox + '[checked]')) {
        var regex = new RegExp('[\?&]$');
        windowUrl = windowUrl.replace(regex, '');
      }

      _ajaxFacets._getData(getDataUrl);
      app.publish('url/updatePath', windowUrl);
    },

    _componentWrappers: {
      facetsWrapper: document.querySelector('.js-facets-wrapper'),
      productWrapper: document.querySelector('[data-component="productsLoader"]'),
      loader: document.querySelector('.js-facets-loading-overlay'),
      mainWrapper: document.querySelector('[data-component="ajaxFacets"]'),
      searchContentWrapper: document.querySelector('.js-search-content'),
      listTitleWrapper: document.querySelector('.js-listTitle-wrapper'),
      listTitleElement: document.querySelector('[data-list-page-title]')
    },

    /**
     * Loading request non blocking
     * @param overlay
     * @private
     */
    _loading: function(overlay) {
      app.element.scrollTo(_ajaxFacets._componentWrappers.mainWrapper, 250);
      if (overlay) {
        app.element.addClass([_ajaxFacets._facetSelectors.showLoadingOverlay_old,
          _ajaxFacets._facetSelectors.showLoadingOverlay_new],
        _ajaxFacets._componentWrappers.loader);
      }
    },

    /**
     * Fire asynchronous call for new page data
     * @param url
     * @private
     */
    _getData: function(url) {
      var overlay = true;
      app.ajax.get({
        url: url,
        dataType: 'JSON',
        error: _ajaxFacets._error,
        success: function(response) {
          var productsLoaded = _ajaxFacets._decoupleResponse(response);
          if (productsLoaded) {
            app.element.removeClass([_ajaxFacets._facetSelectors.showLoadingOverlay_old,
              _ajaxFacets._facetSelectors.showLoadingOverlay_new],
            _ajaxFacets._componentWrappers.loader);
          }
          overlay = false;
          //Provide productQuickbuy hook after AJAX success.
          app.publish('ajaxSuccess/quickView');
          app.publish('productQuickbuy/reinitialise');
          app.publish('addedToBasketModal/reinitialise');
        }
      });
      _ajaxFacets._loading(overlay);
      _ajaxFacets._clearPageNumber = false;
    },

    /**
     * Facet error handler
     * @param error
     * @param why
     * @param method
     * @private
     */
    _error: function(error, why, method) {
      app.element.addClass([_ajaxFacets._facetSelectors.showLoadingOverlay_old,
        _ajaxFacets._facetSelectors.showLoadingOverlay_new],
      _ajaxFacets._componentWrappers.loader);
      try {
        if (method) {
          console.warn(error, why, method);
        } else {
          console.warn(error, error.message, 'getData');
        }
      } catch (e) {
        console.log(e.message, e);
      }
    },

    /**
     * Deconstructing JSON response
     * @param response
     * @private
     */
    _decoupleResponse: function(response) {
      var facetUncheck = app.element.hasClass('js-facet-uncheck', _ajaxFacets._componentWrappers.loader);
      /** @namespace response.productList */
      if (!response.productList) {
        if (!facetUncheck) {
          app.element.addClass([_ajaxFacets._facetSelectors.noProducts_old,
            _ajaxFacets._facetSelectors.noProducts_new],
          _ajaxFacets._componentWrappers.loader);
          _ajaxFacets._error('Could not render facets', 'No products found', 'decoupleResponse');
          return false;
        }
        app.element.removeClass([_ajaxFacets._facetSelectors.showLoadingOverlay_old,
          _ajaxFacets._facetSelectors.showLoadingOverlay_new],
        _ajaxFacets._componentWrappers.loader);
        return;
      }

      app.publish('products/update', response.productList, _ajaxFacets._componentWrappers.productWrapper);

      // This is a temporary addition until all sites have the new listTitle component.
      // After this has been implemented on all sites, this if() can removed.
      if (_ajaxFacets._componentWrappers.listTitleWrapper !== null) {
        app.publish('products/updateListTitle', response.listTitle, _ajaxFacets._componentWrappers.listTitleWrapper);
      }

      if (facetUncheck) {
        app.element.removeClass('js-facet-uncheck', _ajaxFacets._componentWrappers.loader);
      }

      if (_ajaxFacets._componentWrappers.searchContentWrapper) {
        _ajaxFacets._componentWrappers.searchContentWrapper.innerHTML = '';
      }

      /** @namespace response.facetSetList */
      if (response.facetSetList && _ajaxFacets._componentWrappers.facetsWrapper) {
        _ajaxFacets._componentWrappers.facetsWrapper.innerHTML = response.facetSetList;
      }

      /** @namespace response.pagination */
      if (response.pagination) {
        app.publish('pageControls/update', response.pagination);
      }
      return true;
    },

    /**
     * Control the modal click
     * @param event
     * @private
     */
    _modalClick: function(event) {
      event = event || window.event;
      var target = event.target || event.srcElement;
      if (!target) {
        return;
      }

      if (target.nodeName === 'BUTTON' && app.element.hasClass('js-close', target)) {
        _ajaxFacets._closeModal();
      }
    },

    /**
     * Close the modal
     * @private
     */
    _closeModal: function() {
      app.element.removeClass([_ajaxFacets._facetSelectors.noProducts_old,
        _ajaxFacets._facetSelectors.noProducts_new,
        _ajaxFacets._facetSelectors.showLoadingOverlay_old,
        _ajaxFacets._facetSelectors.showLoadingOverlay_new],
      _ajaxFacets._componentWrappers.loader);
      if (app.element.isDomElement(_ajaxFacets._cachedFacet)) {
        _ajaxFacets._cachedFacet.checked = !_ajaxFacets._cachedFacet.checked;
      }
      app.publish('url/updatePath', _ajaxFacets._cachedPath);
    },

    /**
     * Tracking
     * @param facet
     * @private
     */
    _tracking: function(facet) {
      var listPageTitle = app.element.getAttribute('data-list-page-title', _ajaxFacets._componentWrappers.listTitleElement),
        facetTitle = app.element.getAttribute('data-facet-category', facet);

      if (listPageTitle !== null && facetTitle !== null) {
        app.publish('tracking/record', 'Facets', 'click through',
          listPageTitle
          + ' - ' +
          facetTitle
          + ' - ' +
          decodeURIComponent(facet.value)
        );
      }
    },

    /**
     * Remove the existing page number from the URL when applying new filters, if it exists.
     * @param url
     * @private
     */
    _removePageNumberFromUrl: function(url) {
      if (url.indexOf(_ajaxFacets._pageNumberKey) === -1) {
        return url;
      } else {
        var regex = new RegExp('(' + _ajaxFacets._pageNumberKey + '=[0-9^\&]+)');
        return url.replace(regex, '');
      }
    }
  };

  // Execute module constructor
  _ajaxFacets._init();

  // Unit testing access
  return _ajaxFacets;
});
